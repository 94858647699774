<template>
  <b-container class="mb-3">
    <div class="f-radius b-white row pb-0 m__bottom--20">
      <b-container class="p--0 child-container">
        <b-row>
          <b-col class="d-flex">
            <div class="search-result-item row f-radius b-white cursor-pointer">
              <div class="thumbnail">
                <div class="img"
                     :style="imgStyle(getImage(reserveList[0].coalesce))"
                     v-lazy:background-image="getImage(reserveList[0].coalesce)"
                ></div>
              </div>
            </div>
            <span class="p__top--20 m__left--10">No.{{ this.customerNo }}</span>
          </b-col>
          <b-col class="p__top--30">
            <p class="m--0">{{ reserveList[0].customer_name }}</p>
            <p class="m--0">{{ reserveList[0].customer_namekana }}</p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="m__top--10" @click="customerDetail(reserve)" v-for="(reserve, key) in reserveList" :key="key">
      <div class="search-result-item item-container row b-white cursor-pointer">
        <b-row class="w--100">
          <b-col cols="8" class="fs-12 p__right--5">
            <p class="m--0 font-weight-bold">{{ reservedFormat(reserve.reserved_at) }}</p>
            <p class="m--0 line-height-1" v-for="(menu, menuKey) in reserve.menu_array" :key="menuKey">{{ menu }}</p>
          </b-col>
          <b-col cols="4" class="d-flex p__left--5 p__right--5 justify-content-flex-end">
            <div>
              <div class="">
                <b-badge class="badge-grey">{{ reserve.operate_staff_name }}</b-badge>
                <b-badge :style="{ 'background-color': getStatusColor(reserve.status) }">{{ reserve.status }}</b-badge>
              </div>
              <div class="">
                <img v-if="reserve.customer_history_illust_array && reserve.customer_history_illust_array.length" src="@/assets/images/icons/ic_image2.svg" alt="" height="30">
                <img v-if="reserve.customer_history_photo_array && reserve.customer_history_photo_array.length" src="@/assets/images/icons/ic_camera2.svg" alt="" height="30">
                <img v-if="reserve.customer_history_concent_array && reserve.customer_history_concent_array.length" src="@/assets/images/icons/ic_portrait2.svg" alt="" height="30">
              </div>
            </div>
            <div class="icon-arrow-right d-flex align-items-center">
              <img src="@/assets/images/icons/ic_next2.svg" alt="" height="20">
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

  </b-container>
</template>

<script>
import karuteService from "@/services/karute-api";
import customer from "@/mixins/customer";
import {ACTION_BACK_PAGE} from "@/store/auth/actions";

export default {
  name: "Reserved",
  mixins: [customer],
  data() {
    return {
      reserveList: [
        {
          coalesce: '',
          customer_name: '',
          customer_namekana: '',
        }
      ]
    }
  },
  methods: {
    customerDetail(params) {
      this.$router.push({
        name: 'customer-detail',
        params: {
          customer_no: this.customerNo,
          customer_id: this.customerId,
          customer_history_id: params.customer_history_id,
          target_date_from: this.targetDateFrom,
          target_date_to: this.targetDateTo,
        }
      })
    },
    getCustomerReserveList() {
      const data = {
        customer_id: this.customerId,
      }
      karuteService.getCustomerReserveList(data).then((res) => {
        this.reserveList = res.data.result
      })
    },
  },
  created() {
    this.$store.dispatch(ACTION_BACK_PAGE, 'customer-detail')
  },
  watch: {
    locationSelected() {
      this.$router.push({name: 'home'}).then(() => {
        // Customer detail is no longer accessible since store is changed
      });
    },
  },
  mounted() {
    this.getCustomerReserveList()
  }
}
</script>

<style lang="scss" scoped>
.child-container {
  //max-height: ;
}
.container {
  &:last-child {
    padding-bottom: 20px;
  }

  .row {
    margin-left: 0;
    margin-right: 0;

    ::v-deep .col {
      padding: 10px 18px;
      font-size: 14px;
    }
  }
}

.f-radius {
  border-radius: 20px;
  font-size: 1.1rem;
  line-height: 30px;

  &.b-white {
    background: #ffffff;
  }
}

.search-result-item {
  padding: 0.5rem 0;
  display: flex;
  margin: 0;

  .line-height-1 {
    line-height: 1rem;
  }

  &.item-container {
    border-radius: 14px;
    font-size: 1.1rem;
    line-height: 30px;

    &.b-white {
      background: #ffffff;
    }

    .badge {
      align-self: center;
      padding: 7px 10px;
      border-radius: 6px;
      font-weight: normal;
      font-size: 0.6rem;
      min-width: 80px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 20px;
      }

      &.badge-grey {
        background-color: $light-grey;
        color: $dark;
      }

      &.badge-pink {
        background-color: $pink;
      }
    }
  }

  .thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #E6E6E6;

    .img {
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .flex-auto {
    flex: auto;
  }
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}
</style>
